const metrics = {
  metrics: {
    all: "All Categories",
    commitment: "Commitment",
    feedback: "Feedback",
    personalGrowth: "Personal growth",
    recognition: "Recognition",
    managerRelationship: "Relationship with manager",
    teamwork: "Teamwork",
    satisfaction: "Satisfaction",
    wellness: "Well-being",
    withoutMetric: "No category",
    filters: {
      Feedback: "Feedback",
      Commitment: "Compromiso",
      "Personal growth": "Crecimiento personal",
      Recognition: "Reconocimiento",
      "Relationship with manager": "Relación con el manager",
      Teamwork: "Trabajo en equipo",
      Satisfaction: "Satisfacción",
      "Well-being": "Bienestar",
      "All Categories": "Todas las categorias",
      null: null,
    },
    noMetrics: {
      title: "No cycles in progress",
      info: "To see the dashboard, you must start a cycle of micro-survey measurement.",
      alert: "Start your first cycle in the next button. It's easy!",
      button: "Start",
    },
  },
};

export default metrics;
