const metrics = {
  metrics: {
    all: "Todas las categorías",
    commitment: "Compromiso",
    feedback: "Feedback",
    personalGrowth: "Crecimiento personal",
    recognition: "Reconocimiento",
    managerRelationship: "Relación con el manager",
    teamwork: "Trabajo en equipo",
    satisfaction: "Satisfacción",
    wellness: "Bienestar",
    withoutMetric: "Sin métrica asociada",
    filters: {
      Feedback: "Feedback",
      Compromiso: "Compromiso",
      "Crecimiento personal": "Crecimiento personal",
      Reconocimiento: "Reconocimiento",
      "Relación con el manager": "Relación con el manager",
      "Trabajo en equipo": "Trabajo en equipo",
      Satisfacción: "Satisfacción",
      Bienestar: "Bienestar",
      "Todas las categorias": "Todas las categorias",
      null: null,
    },
    noMetrics: {
      title: "No hay ciclos en curso",
      info: "Para ver el dashboard debes comenzar un ciclo de medición de microencuestas.",
      alert: "Inicia tu primer ciclo en el siguiente botón. ¡Es fácil!",
      button: "Comenzar",
    },
  },
};

export default metrics;
