const TimeOff = {
  timeOff: {
    title: "Tempo Livre",
    user: "Usuário",
    team: "Equipa",
    accumulated: "Acumulado",
    requested: "Solicitado",
    pending: "Pendente",
    all: "Todos",
    category: "Categoría",
    status: "Estado",
    approved: "Aprobado",
    rejected: "Rechazado",
    history: "Histórico de solicitudes",
    freeDays: "Días livres",
    noFreeDays: "Nenhuma politica de tempo livre",
    noHistory: "Nenhum histórico de solicitudes",
    detailTitle: "Detalhe de uso de dias livres",
    headersDays: {
      category: "Categoria",
      politic: "Política",
      solicited: "Solicitado",
      accumulated: "Acumulado",
    },
    headersHistory: {
      date: "Data",
      politic: "Política",
      solicited: "Solicitado",
      approved: "Aprovado",
      state: "Estado",
    },
    filter: {
      team: "Filtrar por equipa",
      policy: "Filtrar por política",
    },
    tabs: {
      all: "Todos",
      admin: "Administrativo",
      legal: "Legal",
      additional: "Adicional",
    },
    language: "português",
  },
};

export default TimeOff;
