const userFeedback = {
  userFeedback: {
    sent: "Feedbacks sent",
    back: "Back to Feedback",
    roles: {
      all: "All",
      employee: "Collaborator",
      manager: "Manager",
    },
    feedbackTracking: {
      title: "Feedback Tracking",
      back: "Back to Feedback",
    },
    search: {
      notFound: "We couldn't find any user with that name.",
    },
    header: {
      avatar: "",
      name: "User",
      sent: "Sent",
      received: "Received",
      pending: "Pending",
      role: "Role",
      last: "Last feedback",
      avg: "Feedback average",
      graph: "Trend of last 5 feedbacks",
    },
    evaluations: {
      veryLow: "Does not meet expectations",
      low: "Partially meets expectations",
      medium: "Meets expectations",
      high: "🚀 Exceeds expectations",
      veryHigh: "🦄 Is an industry benchmark",
      noData: "No data available",
    },
    detailsModal: {
      title: "Performance feedback",
      searchPlaceholder: "Search for person",
      commentTitle: "Comment",
      average: "Average",
      segment: "Segment: ",
      comment: "Comment: ",
      start: "Start: ",
      stop: "Stop: ",
      continue: "Continue: ",
      sent: "Total sent: ",
      pending: "Total pending:",
      received: "Total received:",
      tooltip:
        "Only feedback that has been evaluated using a numeric rating from 1 to 5 will be visible.",
    },
    exportModal: {
      title: "Feedbacks Export",
      exportButton: "Export ",
      startDatePicker: "From:",
      endDatePicker: "To:",
      endDatePlaceholder: "Select a start date",
      addTeams: "Add teams",
      addProperties: "Add properties",
      emailLabel: "Enter an email:",
      emailPlaceholder: "example@email.com",
      sendButton: "Send",
      alertSuccess:
        "It has been sent successfully! This may take a few minutes.",
      sending: "Sending...",
      error: {
        NO_EMAIL: "Email address is required.",
        MISSING_PARAMETERS:
          "Missing required parameters. Please contact support.",
        NO_FEEDBACKS: "No feedbacks found for this account.",
        INVALID_DATE: "Invalid date.",
        NO_FEEDBACKS_IN_DATERANGE: "No feedbacks found within date range.",
        INTERNAL_ERROR:
          "Unexpected error occurred, please try again or contact support.",
      },
    },
  },
};

export default userFeedback;
