const users = {
  users: {
    overview: {
      income: "Ingreso: {{income}}",
      daysAgo: "({{days}} días)",
      daysAgoTitle: "Desde el {{ daysTitle }}",
      daysLeft: "Faltan {{days}} días para su cumpleaños",
      birthday: "Fecha de Nacimiento: {{birthday}}",
      role: "Rol: {{role}}",
      personalInfo: "Información Personal",
      employmentInformation: "Información Laboral",
      emergencyContact: "Contacto de emergencia",
      required: "Requerido *",
      identityIdType: "Tipo de documento",
      identityIdNumber: "Número de documento",
      requiredIdentityId: "Requerido *",
      location: "Ubicación",
      permissions: "Permisos",
      userType: "Tipo de usuario",
      individualContributor: "Contribuidor individual",
      teamLeader: "Líder de equipo",
      unassigned: "Sin asignar",
      withoutTeam: "Sin equipo",
      managerIn: "(Manager en {{teams}})",
      bankDetails: "Datos bancarios",
      placeholderDate: "dd/mm/aaaa",
      tabSwitcher: {
        firstName: "Nombre",
        lastName: "Apellido",
        email: "Email",
        phoneNumber: "Número de contacto",
        gender: "Género",
        country: "País",
        type: "Tipo de cuenta",
        employeeId: "ID de empleado",
        city: "Ciudad",
        hierarchyLevel: "Nivel jerárquico",
        province: "Provincia",
        personalId: "Documento de identidad",
        address: "Dirección",
        birthDate: "Fecha de Nacimiento",
        emergencyContactFirstName: "Nombre",
        emergencyContactLastName: "Apellido",
        emergencyContactPhoneNumber: "Número de contacto",
        emergencyContactRelationship: "Relación",
        role: "Rol",
        area: "Área",
        hiringDate: "Fecha de contratación",
        correlative: "Número único de colaborador (NUC)",
        contractType: "Tipo de contrato",
        salary: "Sueldo",
        teams: "Equipos",
        hisManager: "Manager a cargo: ",
        personalEmail: "Email personal",
        position: "Cargo",
        team: "Equipo",
        bank: "Nombre del banco",
        number: "Número de la cuenta",
      },
      tabs: {
        summary: "Resumen",
        properties: "Propiedades",
        activity: "Actividad",
        settings: "Configuración",
      },
      options: {
        contractType: {
          fullTime: "Tiempo completo",
          partTime: "Medio tiempo",
          contractor: "Contratista",
        },
        gender: {
          male: "Masculino",
          female: "Femenino",
          other: "Otro",
        },
        emergencyContactRelationship: {
          spouse: "Cónyuge",
          friend: "Amistad",
          family: "Familiar",
        },
      },
    },
    allReminder:
      "¿Seguro que deseas enviar un recordatorio a todos los usuarios inactivos?",
    notResults: "No hubo resultados. Intenta cambiando tu búsqueda ",
    userDelete: "¿Estás seguro de eliminar al usuario?",
    userReminder: "¿Seguro que deseas enviar un recordatorio al usuario?",
    invitationSelect: "Selecciona una forma de invitación",
    admin: "Administrador",
    employee: "Colaborador",
    manager: "Manager",
    type: "Rol",
    onboardingType: {
      initial: "¿Seguro que deseas enviar encuesta inicial?",
      onboarding: "¿Seguro que deseas enviar encuesta onboarding?",
    },
    card: {
      withoutName: "Sin Nombre",
      active: "Activo",
      inactive: "Inactivo",
      pending: "Pendiente",
      deactivated: "Desactivado",
      onboarding: "Onboarding",
      activation: "Activación",
      all: "Todos",
      tooltip: "Envía un recordatorio para que el usuario active su cuenta",
      tooltipRMAdd: "Incluir a la participación de random meetings futuros",
      tooltipRMRemove: "Excluir de la participación de random meetings futuros",
      tooltipActivation:
        "Elige el tipo de invitación para que el usuario active su cuenta",
    },
    edit: {
      title: "Editar usuario",
      required: "Los campos marcados con * son obligatorios.",
      names: "Nombres",
      lastNames: "Apellidos",
      email: "Email*",
      birthday: "Fecha de cumpleaños",
      anniversary: "Aniversario de trabajo",
      receiveSurvey: "Habilitado para recibir encuestas",
      success: "Usuario actualizado exitosamente!",
      errors: {
        email: "Requerido *",
        update: "Error actualizando el usuario. Intenta de nuevo",
        disabledDate: "Requerido *",
      },
      properties: {
        title: "Propiedades",
        name: "Nombre propiedad",
        value: "Valor",
      },
      genders: {
        gender: "Género",
        male: "Masculino",
        female: "Femenino",
        other: "Otro",
      },
    },
    delete: {
      title:
        "Para seguir mejorando tu experiencia, cuentanos por qué estás eliminando a este usuario",
      subtitle: "Elige una subcategoría",
      confirm: "Confirmar",
      cancel: "Cancelar",
      categories: {
        voluntary: {
          text: "Renuncia",
          subcategories: {
            OPPORTUNITY: "Otra oportunidad laboral",
            PERSONAL_PROBLEMS: "Problemas personales",
            STRESS: "Estrés",
            CULTURE: "Sin match cultural",
            MUTUAL_AGREEMENT: "Mutuo acuerdo",
          },
        },
        dismissal: {
          text: "Desvinculación",
          subcategories: {
            END: "Término de plazo",
            RESTRUCTURING: "Reestructuración interna",
            PERFORMANCE: "Bajo rendimiento",
            AREA_CLOSURE: "Cierre de área",
            CULTURE: "Sin match cultural",
          },
        },
        other: {
          text: "Otro",
          subcategories: {
            DUPLICATE: "Duplicado",
            GENERIC_EMAIL: "Correo genérico",
            INCORRECT_EMAIL: "Correo incorrecto",
            DOES_NOT_WORK_HERE_ANYMORE: "Ya no trabaja aquí",
          },
        },
      },
    },
    header: {
      title: "Usuarios",
      total: "Total ({{total}}) ",
      actives: "Activos ({{actives}})",
      inactives: "Inactivos ({{inactives}})",
      pending: "Pendientes ({{pendings}})",
      deactivateds: "Desactivados ({{deactivateds}})",
      admins: "Admins ({{admins}})",
      onboarding: "Onboardings ({{onboardings}})",
      selectStatus: "Seleccionar el estado",
      searchPlaceholder: "Buscar colaborador",
      inviteMembers: "Invitar",
      integrateToMsTeamsInBatch: "Integrar usuarios a Microsoft Teams",
      inviteTooltip:
        "Envía un recordatorio a todos tus colaboradores inactivos",
    },
    table: {
      user: "Usuario",
      status: "Estado",
      participation: "Actividades",
    },
    email: {
      title: "Invita a tu equipo",
      subTitle: "El colaborador recibirá un link para activar su cuenta",
      helperText: "Email inválido",
      placeholder: "nombre@empresa.com",
      button: "Enviar Invitación",
    },
    slack: {
      title: "Invita a tus colaboradores seleccionando tu canal de Slack",
      loadingText: "Cargando...",
      noOptions: "Sin opciones",
      invite: {
        successful: "Invitaciones enviadas exitosamente",
        noInvitations:
          "No se enviaron invitaciones. Revisa el canal que elegiste.",
      },
      reminder:
        "*Importante: Solo se enviarán invitaciones a aquellos usuarios que no existan en tu cuenta. ",
      submit: {
        title:
          "Se enviarán invitaciones a {{members}} colaboradores de canal {{name}}",
        loading: "Estamos dejando todo listo",
        invite: "¡Invitar!",
      },
    },
    excel: {
      import: {
        teamSummary: {
          header: {
            team: "Equipo",
            comments: "Comentarios",
          },
          statuses: {
            ok: "OK",
            teamAlreadyExist: "Equipo ya existe",
            errorSavingTeam: "Error guardando el equipo",
            teamNameEmpty: "Equipo sin nombre",
            teamWithoutManager: "Equipo sin manager",
            userWithTeam: "Usuario ya tiene equipo",
            unexistentUser: "Usuario no existe en la cuenta",
          },
        },
        userSummary: {
          header: {
            row: "Fila",
            email: "Email",
            comments: "Comentarios",
          },
          statuses: {
            ok: "OK",
            errorSavingUser: "Error inesperado creando al colaborador",
            userAlreadyExist: "Colaborador ya exsite",
            mailNotFound: "El campo email es obligatorio",
            invalidEmail: "El campo email no es válido",
          },
        },
        usersTitle: "Importa a tus colaboradores",
        usersLoad: "Carga tus colaboradores",
        teamsTitle: "Importa tus equipos",
        teamsLoad: "Carga tus equipos",
        users: "colaboradores",
        user: "colaborador",
        teams: "equipos",
        team: "equipo",
        drag: "Cargar acá",
        download: "Descargar plantilla",
        emptyText:
          "{{errorCount}} {{errorType}} no han podido ser importados, revisa el detalle aquí.",
        successText: "Se han importado exitosamente {{successCount}} {{type}}",
        errorText:
          "Se han importado {{successCount}} {{successType}}. Sin embargo, {{errorCount}} {{errorType}} presentaron alguna observación, revisa el detalle aquí.",
      },
      success: {
        title: "Se ha subido tu archivo",
        button: "Continuar",
      },
      error: {
        title: "Ups! no hemos podido cargar tu archivo",
        subTitle:
          "Revisa tu conexión de internet o que el archivo esté correcto.",
        button: "Reintentar",
      },
    },
    msTeamsInvite: {
      title: "Invitación por Microsoft teams",
      subTitle: "Importa a tu equipo desde tu canal de Microsoft teams",
      adminConsent:
        "Por favor da consentimiento de administrador para integrarnos a Microsoft teams",
      grantConsent: "Otorgar consentimiento",
      inviteForm: {
        title: "¡Ya estás casi listo!",
        subTitle:
          "Invita a tus colaboradores seleccionando tu canal de Microsoft teams",
        proTip:
          "*Pro tip: Se invitarán a todos los miembros del team seleccionado",
        disclaimer:
          "Se enviarán invitaciones a los {{groupCount}} colaboradores del team",
        lightDisclaimer:
          "Se enviarán invitaciones a los colaboradores del team {{team}}",
        onProgress: "Estamos dejando todo listo",
        onProgressGroups: "Estamos cargando el listado de tus equipos",
        submit: "¡Invitar!",
      },
    },
    invite: {
      channel: "Selecciona el método de envío",
      invitationType: "Selecciona el tipo de invitación",
      email: {
        title: "Invitación por email",
        subtitle: "Baja un poco el ritmo e invita a tu equipo por correo",
      },
      slack: {
        title: "Invitación por Slack",
        subtitle: "Importa a tu equipo desde tu canal de Slack",
      },
      excel: {
        title: "Invitación por excel",
        subtitle: "Invita a múltiples miembros del equipo de una sola vez",
      },
      type: {
        initial: {
          title: "Encuesta inicial",
          description:
            "Se enviará una encuesta inicial de 8 preguntas para que activen su cuenta",
        },
        onboarding: {
          title: "Encuesta de onboarding para colaboradores nuevos",
          description:
            "Se enviará una encuesta inicial sobre su proceso de reclutamiento y una encuesta al mes sobre su proceso de onboarding",
        },
      },
    },
    cardActions: {
      viewProfile: "Ver perfil",
      edit: "Editar usuario",
      reminder: "Enviar recordatorio",
      msTeams: "Integrar a Microsoft Teams",
      msTeamsIntegrateTitle: "Integrando a Microsoft Teams",
      msTeamsIntegrateSuccess: "Integrado existosamente!",
      msTeamsIntegrateClose: "Cerrar",
      delete: "Eliminar usuario",
      excludeToRM: "Excluir de RM",
      includeToRM: "Incluir al RM",
    },
    multipleSelect: {
      deselectAll: "Deseleccionar todos",
      deleteAll: "Eliminar todos",
      tooltipDeleteAll:
        "Todos los usuarios seleccionados se borraran con el mismo motivo",
      communications: "Editar actividad",
    },
    communications: {
      title: "Preferencias de participación",
      general: "Participación general",
      titleSwitch:
        "Edita la participación de los colaboradores en las siguientes actividades",
      randomMeeting: "Random meeting",
      feedback: "Feedback",
      customSurvey: "Encuesta personalizada",
      microSurvey: "Microencuesta",
      recognitionRequest: "Solicitud de reconocimiento",
      participate: {
        in: "Participar de {{eventType}}",
        diableUntil: "Deshabilitar participación hasta",
        subtitle:
          "Se habilitara automaticamente al transcurrir la fecha o puedes habilitarlo manualmente",
      },
      holiday: "Ausente de actividades",
    },
  },
};

export default users;
