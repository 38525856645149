const calendar = {
  calendar: {
    event: {
      newEvent: "New event",
      editEvent: "Edit event",
      moreEvent: "more event",
      moreEvents: "more events",
      createEvent: {
        selectDate: "Date",
        selectHour: "Hour",
        selectMinutes: "Minutes",
      },
      save: "Save",
      type: "Event type",
      tooltipThereIsRecognition:
        "Just a reminder, you can only send one recognition per month, and you can schedule it only if there are more than two business days left before the end of the month",
      tooltipAddReport:
        "The individual summaries will only be sent to the collaborators with recognition",
      teams: "Teams",
      segments: "Segments",
      toChoose: "Select groups or segments",
      chooseTemplate: "Select the template",
    },
    createdEvent: {
      message: "Scheduled event!",
    },
    updateEvent: {
      message: "Event updated!",
      errorMessage: "error",
      updateOne: "This event",
      updateAll: "All events",
      updateOneTooltip: "Only applies to the date",
    },
    deleteEvent: {
      message: "Event deleted!",
      undoDeleted: "Undo",
      deleteOne: "This event",
      deleteAll: "All events",
    },
    toolbar: {
      newEvent: "New event",
      month: "Month",
      week: "Week",
      day: "Day",
      today: "Today",
      list: "List",
    },
    types: {
      microSurvey: "Microsurvey",
      feedback: "Organizational feedback",
      userFeedback: "Feedback",
      customSurvey: "Custom survey",
      onboarding: "Onboarding",
      recognitionRequest: "Recognition request",
      recognitionReport: "Recognition report",
      randomMeeting: "Random meeting",
      questionsReminder: "Question reminder",
      userFeedbackStart: "Feedback start",
      userFeedbackEnd: "Feedback end",
      userFeedbackReminder: "Feedback reminder",
    },
    recognitions: {
      info: "Recognitions are only sent once a month",
      infoTwo:
        "In order for employees to receive their recognitions, you must schedule the individual summaries 😍",
      dateValidator: "The summary must be at least 1 day after the order",
    },
    feedbackCustom: {
      directions: "Directions",
      titleSelect: "Repeat every",
      startDate: "Start date",
      endDate: "End date",
      remenderDate: "Reminder date",
      repetFeedback: "Repeat event every",
      start: "Select the start date",
      end: "Select the end date",
      reminder: "Select the reminder date",
      monthsOne: "Every 1 month",
      monthsTwo: "Every 2 months",
      monthsThree: "Every 3 months",
      orientation: "Orientation",
    },
    microSurvey: {
      questionsQuantity: "Quantity of questions",
    },
    customSurvey: {
      anonymous: "Is anonymous",
      notAnonymous: "Not anonymous",
    },
    randomMeeting: {
      groupSize: "Group size",
      concurrence: "Recurrence of Random Meetings",
      everyWeek: "Every {{ value }} week",
      everyWeeks: "Every {{ value }} weeks",
    },
    audience: {
      title: "Audience",
      selectTeam: "Select team",
      selectSegment: "Select segment",
      all: "All",
      team: "Team",
      segment: "Segment",
      teamSelect: "Select teams",
      segmentSelect: "Select segments",
      tooltipTextAudience:
        "The team or segment must have at least 4 active users to be included in the audience",
    },
    repeatEvent: {
      title: "Repeat event",
      everyDay: "Every workday",
      frequencySelect: "Frequency",
      everyWeek: "Every week on {{day}}",
      everyTwoWeeks: "Every two weeks on {{day}}",
      everyMonth: "Every month, the {{week}} {{day}}",
      everyTwoMonths: "Bi-monthly on {{week}} {{day}}",
      everyThreeMonths: "Every three months on {{week}} {{day}}",
      everyYear: "Annually on the {{week}} {{day}} of {{month}}",
      week: {
        1: "first",
        2: "second",
        3: "third",
        4: "fourth",
        5: "fifth",
      },
      day: {
        1: "monday",
        2: "tuesday",
        3: "wednesday",
        4: "thurday",
        5: "friday",
      },
      last: "last",
      custom: {
        title: "Customize...",
        repeat: "Repeat every",
        week: "week",
        month: "month",
        year: "year",
        weeks: "weeks",
        months: "months",
        years: "years",
        itRepeats: "It repeats",
        errorValidate: "Select at least one",
      },
    },
    sentTo: {
      title: "Sent to",
      content: "{{sentTo}} collaborators",
    },
    feedbackTracking: {
      user: "User",
      participation: "feedbacks delivered",
      percentage: "progress",
    },
    date: "Date",
  },
};

export default calendar;
