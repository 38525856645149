const users = {
  users: {
    overview: {
      income: "Renda: {{income}}",
      daysAgoTitle: "Desde {{ daysTitle }}",
      daysAgo: "({{days}} dias)",
      daysLeft: "Faltam {{days}} dias para o seu aniversário",
      birthday: "Data de Nascimento: {{birthday}}",
      role: "Função: {{role}}",
      personalInfo: "Informações Pessoais",
      employmentInformation: "Informações de Trabalho",
      emergencyContact: "Contato de Emergência",
      required: "Obrigatório *",
      identityIdType: "Tipo de documento",
      identityIdNumber: "Número de documento",
      requiredIdentityId: "Obrigatório *",
      location: "Localização",
      userType: "Tipo de Usuário",
      permissions: "Permissões",
      individualContributor: "Contribuidor individual",
      teamLeader: "Líder de equipe",
      unassigned: "Não atribuído",
      withoutTeam: "Sem equipe",
      managerIn: "(Manager em {{teams}})",
      bankDetails: "Dados bancários",
      placeholderDate: "dd/mm/aaaa",
      tabSwitcher: {
        firstName: "Nome",
        lastName: "Sobrenome",
        country: "País",
        accountType: "Tipo de conta",
        employeeId: "ID de colaborador",
        city: "Cidade",
        hierarchyLevel: "Nível hierárquico",
        province: "Província",
        personalId: "Documento de identidade",
        address: "Endereço",
        email: "Email",
        phoneNumber: "Número de contato",
        gender: "Gênero",
        birthDate: "Data de Nascimento",
        emergencyContactFirstName: "Nome",
        emergencyContactLastName: "Sobrenome",
        emergencyContactPhoneNumber: "Número de contato",
        emergencyContactRelationship: "Parentesco",
        role: "Função",
        area: "Área",
        hiringDate: "Data de Contratação",
        correlative: "Número Único de Colaborador (NUC)",
        contractType: "Tipo de Contrato",
        salary: "Salário",
        teams: "Equipes",
        hisManager: "Gerente responsável: ",
        personalEmail: "Email Pessoal",
        position: "Cargo",
        team: "Equipe",
        bank: "Nome do banco",
        number: "Número da conta",
      },
      tabs: {
        summary: "Resumo",
        properties: "Propriedades",
        activity: "Atividade",
        settings: "Configurações",
      },
      options: {
        contractType: {
          fullTime: "Tempo integral",
          partTime: "Meio período",
          contractor: "Prestador de serviços",
        },
        emergencyContactRelationship: {
          spouse: "Cônjuge",
          friend: "Amigo",
          family: "Familiar",
        },
        gender: {
          male: "Masculino",
          female: "Feminino",
          other: "Outro",
        },
      },
    },
    allReminder:
      "Tem certeza de que deseja enviar um lembrete para todos os usuários inativos?",
    notResults: "Nenhum resultado encontrado. Tente alterar sua pesquisa.",
    userDelete: "Tem certeza de que deseja excluir o usuário?",
    userReminder:
      "Tem certeza de que deseja enviar um lembrete para o usuário?",
    invitationSelect: "Selecione um método de convite",
    admin: "Administrador",
    employee: "Colaborador",
    manager: "Gerente",
    type: "Tipo",
    onboardingType: {
      initial: "Você tem certeza de que deseja enviar a pesquisa inicial?",
      onboarding:
        "Você tem certeza de que deseja enviar a pesquisa de integração?",
    },
    card: {
      withoutName: "Sem Nome",
      active: "Ativo",
      inactive: "Inativo",
      pending: "Pendente",
      onboarding: "Integração",
      deactivated: "Desativado",
      activation: "Ativação",
      all: "Todos",
      tooltip: "Envie um lembrete para o usuário ativar sua conta",
      tooltipRMAdd: "Inclua-o na participação de futuras random meetings",
      tooltipRMRemove: "Excluir da participação em futuras random meetings",
      tooltipActivation:
        "Escolha o tipo de convite para que o usuário ative sua conta",
    },
    edit: {
      title: "Editar usuário",
      required: "Os campos marcados com * são obrigatórios.",
      names: "Nomes",
      lastNames: "Sobrenomes",
      email: "Email*",
      birthday: "Aniversário",
      anniversary: "Aniversário de Trabalho",
      receiveSurvey: "Habilitado para receber pesquisas",
      success: "Usuário atualizado com sucesso!",
      errors: {
        email: "Obrigatório *",
        update: "Erro ao atualizar o usuário. Tente novamente",
      },
      properties: {
        title: "Propriedades",
        name: "Nome da propriedade",
        value: "Valor",
      },
      genders: {
        gender: "Gênero",
        male: "Masculino",
        female: "Feminino",
        other: "Outro",
      },
    },
    delete: {
      title:
        "Para melhorar sua experiência, conte-nos por que você está excluindo este usuário",
      subtitle: "Escolha uma subcategoria",
      confirm: "Confirmar",
      cancel: "Cancelar",
      categories: {
        voluntary: {
          text: "Renúncia",
          subcategories: {
            OPPORTUNITY: "Outra oportunidade de trabalho",
            PERSONAL_PROBLEMS: "Problemas pessoais",
            STRESS: "Estresse",
            CULTURE: "Incompatibilidade cultural",
            MUTUAL_AGREEMENT: "Acordo mútuo",
          },
        },
        dismissal: {
          text: "Desligamento",
          subcategories: {
            END: "Final do prazo",
            RESTRUCTURING: "Reestruturação interna",
            PERFORMANCE: "Baixo desempenho",
            AREA_CLOSURE: "Encerramento de área",
            CULTURE: "Incompatibilidade cultural",
          },
        },
        other: {
          text: "Outro",
          subcategories: {
            DUPLICATE: "Duplicado",
            GENERIC_EMAIL: "Email genérico",
            INCORRECT_EMAIL: "Email incorreto",
            DOES_NOT_WORK_HERE_ANYMORE: "Não trabalha mais aqui",
          },
        },
      },
    },
    header: {
      title: "Usuários",
      total: "Total ({{total}})",
      actives: "Ativos ({{actives}})",
      inactives: "Inativos ({{inactives}})",
      pending: "Pendentes ({{pendings}})",
      onboarding: "Integraçãos ({{onboardings}})",
      admins: "Admins ({{admins}})",
      deactivateds: "Desativados ({{deactivateds}})",
      administrators: "Administradores",
      selectStatus: "Selecionar status",
      searchPlaceholder: "Pesquisar colaborador",
      inviteMembers: "Convidar",
      integrateToMsTeamsInBatch: "Usuários integrados ao Microsoft Teams",
      inviteTooltip:
        "Envie um lembrete para todos os seus colaboradores inativos",
    },
    table: {
      user: "Usuário",
      status: "Status",
      participation: "Actividades",
    },
    email: {
      title: "Convide sua equipe",
      subTitle: "O colaborador receberá um link para ativar sua conta",
      helperText: "Email inválido",
      placeholder: "nome@empresa.com",
      button: "Enviar Convite",
    },
    slack: {
      title: "Convide seus colaboradores selecionando seu canal do Slack",
      loadingText: "Carregando...",
      noOptions: "Sem opções",
      reminder:
        "*Importante: Os convites serão enviados apenas para usuários que não existam em sua conta.",
      invite: {
        successful: "Convites enviados com sucesso",
        noInvitations:
          "Nenhum convite foi enviado. Por favor, verifique o canal selecionado.",
      },
      submit: {
        title:
          "Convites serão enviados para {{members}} colaboradores do canal {{name}}",
        loading: "Estamos preparando tudo",
        invite: "Convidar!",
      },
    },
    excel: {
      import: {
        teamSummary: {
          header: {
            team: "Equipe",
            comments: "Comentários",
          },
          statuses: {
            ok: "OK",
            teamAlreadyExist: "Equipe já existe",
            errorSavingTeam: "Erro ao salvar a equipe",
            teamNameEmpty: "Equipe sem nome",
            teamWithoutManager: "Equipe sem gerente",
            userWithTeam: "Usuário já possui equipe",
            unexistentUser: "Usuário não existe na conta",
          },
        },
        userSummary: {
          header: {
            row: "Linha",
            email: "Email",
            comments: "Comentários",
          },
          statuses: {
            ok: "OK",
            errorSavingUser: "Erro inesperado ao criar o colaborador",
            userAlreadyExist: "Colaborador já existe",
            mailNotFound: "O campo de email é obrigatório",
            invalidEmail: "O campo de email não é válido",
          },
        },
        usersTitle: "Importe seus colaboradores",
        usersLoad: "Carregue seus colaboradores",
        teamsTitle: "Importe suas equipes",
        teamsLoad: "Carregue suas equipes",
        users: "colaboradores",
        user: "colaborador",
        teams: "equipes",
        team: "equipe",
        drag: "Arraste e solte aqui",
        download: "Baixar modelo",
        emptyText:
          "{{errorCount}} {{errorType}} não puderam ser importados, verifique os detalhes aqui.",
        successText: "{{successCount}} {{type}} importados com sucesso",
        errorText:
          "{{successCount}} {{successType}} foram importados. No entanto, {{errorCount}} {{errorType}} apresentaram observações, verifique os detalhes aqui.",
      },
      success: {
        title: "Seu arquivo foi enviado",
        button: "Continuar",
      },
      error: {
        title: "Oops! Não conseguimos carregar seu arquivo",
        subTitle:
          "Verifique sua conexão com a internet ou se o arquivo está correto.",
        button: "Tente novamente",
      },
    },
    msTeamsInvite: {
      title: "Convite por Microsoft Teams",
      subTitle: "Importe sua equipe do seu canal do Microsoft Teams",
      adminConsent:
        "Por favor, dê consentimento de administrador para nos integrarmos ao Microsoft Teams",
      grantConsent: "Conceder consentimento",
      inviteForm: {
        title: "Você está quase lá!",
        subTitle:
          "Convide seus colaboradores selecionando seu canal do Microsoft Teams",
        proTip:
          "*Dica: Todos os membros da equipe selecionada serão convidados",
        disclaimer:
          "Convites serão enviados para os {{groupCount}} colaboradores da equipe",
        lightDisclaimer:
          "Convites serão enviados para os colaboradores da equipe {{team}}",
        onProgress: "Estamos preparando tudo",
        onProgressGroups: "Estamos carregando a lista de suas equipes",
        submit: "Convidar!",
      },
    },
    invite: {
      slack: {
        title: "Convite por Slack",
        subtitle: "Importe sua equipe do seu canal do Slack",
      },
      excel: {
        title: "Convite por Excel",
        subtitle: "Convide vários membros da equipe de uma só vez",
      },
      email: {
        title: "Convite por email",
        subtitle: "Diminua um pouco o ritmo e convide sua equipe por e-mail",
      },
      type: {
        initial: {
          title: "Pesquisa Inicial",
          description:
            "Será enviada uma pesquisa inicial com 8 perguntas para ativação da conta.",
        },
        onboarding: {
          title: "Pesquisa de Onboarding para Novos Colaboradores",
          description:
            "Será enviada uma pesquisa inicial sobre seu processo de recrutamento e uma pesquisa mensal sobre seu processo de onboarding.",
        },
      },
      channel: "Selecione o método de entrega",
      invitationType: "Selecione o tipo de convite",
    },
    cardActions: {
      viewProfile: "Ver perfil",
      edit: "Editar usuário",
      reminder: "Enviar lembrete",
      msTeams: "Integre ao Microsoft Teams",
      msTeamsIntegrateTitle: "Integração ao Microsoft Teams",
      msTeamsIntegrateSuccess: "Integrado com sucesso!",
      msTeamsIntegrateClose: "Fechar",
      delete: "Excluir usuário",
    },
    multipleSelect: {
      deselectAll: "Desmarcar todos",
      deleteAll: "Eliminar todos",
      tooltipDeleteAll:
        "Todos os usuários selecionados serão excluídos com o mesmo motivo",
      communications: "Editar actividade",
    },
    communications: {
      title: "Preferências de participação",
      general: "Participação geral",
      titleSwitch:
        "Edite o envolvimento dos participantes nas seguintes atividades",
      randomMeeting: "Random meeting",
      feedback: "Feedback",
      customSurvey: "Pesquisa personalizada",
      microSurvey: "Micro pesquisa,",
      recognitionRequest: "Solicitação de reconhecimento",
      participate: {
        in: "Participar de {{eventType}}",
        diableUntil: "Desabilitar participação até",
      },
      holiday: "Ausente de actividades",
    },
  },
};

export default users;
